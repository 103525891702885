/* Global CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif !important;
}

.main {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-focus:focus {
  border-color: #10a37f !important;
  box-shadow: 0 0 0 0.03rem rgba(16, 163, 127, 0.25) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #004d31 !important;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 10px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

h7 {
  font-size: 8px;
}

.feedback-link p {
  font-size: 12px;
}

.skills-section {
  text-align: center;
}

.skills-section h6 {
  padding-top: 3rem;
  padding-bottom: 1rem;
  font-weight: 500;
}

.version-legal-text {
  font-size: 10px;
}

.must-have-skills,
.optional-have-skills {
  font-weight: 500;
  color: #10a37f;
}

.prediction-rational p,
.filterd-result label {
  color: #10a37f;
  font-size: 13px;
}

.filterd-result .form-check {
  padding-left: 2.5rem;
}

.optional-have-skills p {
  font-size: 13px;
  margin-bottom: 0.25rem;
  text-transform: capitalize;
}

.must-have-skills p {
  font-size: 14px;
  margin-bottom: 0.25rem;
}

.prediction-rational {
  margin: 1rem;
}

.border-top-divider {
  border-top: 1.8px solid lightgray;
}

.border-bottom-divider {
  border-bottom: 1.8px solid lightgray;
}

.primary-path,
.secondary-paths {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.35rem;
  padding-right: 0.35rem;

  border-radius: 5px;
  line-height: 1.6;
  cursor: pointer;
  border: 1.8px solid #10a37f;
}

.secondary-path-section,
.primary-path-section,
.skill-section {
  padding-top: 20px;
  padding-bottom: 20px;
}

.sections {
  padding-top: 80px;
}

.divider-break {
  padding-bottom: 40px;
}

.custom-paper {
  background-color: #f2fdfb;
  /* WHITE #fff */
  padding: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-bottom: 20px;
}

.secondary-paths div:last-child {
  border-bottom: none;
}

.primary-path p,
.secondary-paths p,
.primary-path a,
.secondary-paths a {
  display: inline;
  padding-right: 1rem;
  font-size: 14px;
  padding-bottom: 0;
  color: #10a37f;
}

.secondary-paths div {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid lightgray;
}

.path-details p {
  font-size: 14px;
}

.secondary-paths a:hover,
.primary-path a:hover {
  opacity: 0.8;
}

.primary-path i,
.secondary-paths i,
.path-details i {
  color: #10a37f;
  font-size: 1rem;
  font-weight: 500;
  padding-right: 0.25rem;
}

.career-path-section h5 {
  color: #10a37f;
  font-size: 22px;
  font-weight: 500;
}

.career-path-section h6,
.path-details h6 {
  color: #212529 !important;
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 2px;
}

.search-section input {
  border-radius: 16px;
  padding: 8px;
}

.person-walking {
  font-size: 24px !important;
  font-weight: 500;
}

.spinner-block {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.course-details p {
  margin-bottom: 0.5rem;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.course-details p label {
  font-weight: 400;
}

.course-details p span {
  font-weight: 600;
}

/* Icons */
.icon {
  font-size: 1.5rem;
  color: #d9d8db;
  cursor: pointer;
}

.icon:hover {
  color: #10a37f;
  cursor: pointer;
}
