/* Header.css */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem 0.5rem 0.5rem;
  background-color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-bottom: linear-gradient(
    to right,
    #10a37f,
    #e74c3c
  ); /* Adjust colors as needed */
}
.header-icons {
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 1rem;
}

.img-logo {
  height: 40px;
  width: auto;
  padding-top: 10px;
}
