.divider-inner {
  padding-top: 10px;
  border-bottom: 1px solid #ccc;
  width: 100%;
}

.divider-wrapper {
  padding-bottom: 10px;
}

.divider-wrapper,
.divider-inner {
  padding-left: 0px;
  padding-right: 0px;
}
