.searchbar-wrapper {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.student-root {
  width: 100%;
  min-width: 800px;
}

.options-group {
  padding-top: 10px;
}

.checkbox {
  padding-right: 20px;
}

.textinput{
  margin-top: 3px;
}
.textarea-container {
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%;
}

textarea {
  width: 300px;
  height: 200px;
  overflow: auto;
}

textarea::placeholder{
 opacity: 0.5 !important;
}
.container {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 45px;
}
.button-row {
  display: flex;
  gap: 10px;
}
button {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s ease;
}
button:hover {
  background-color: #10a37f;
}
.char-count {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

.footer{
  padding:5px;
  display:flex;
  flex-direction:column;
  width:100% !important
}

.footer span{
  align-self: flex-end;
}

.modal {
  --bs-modal-zindex: 1055 !important;
  --bs-modal-width: 1000px !important;
}
